<template>
  <div id="Footer">
    <div class="footerCenter">
      <div class="footerCenterLeft">
        <div class="footerMsg">
          <div>
            <div class="footDiv1">
              <p>快捷入口</p>
              <span class="tiaozhuan" @click="$router.push('/meeting')">产品</span>
              <span class="tiaozhuan" @click="goHHditing">海河谛听</span>
              <span class="tiaozhuan" @click="goAIplatform">AI平台</span>
              <span class="tiaozhuan" @click="goHYhuiting">慧言会听</span>
            </div>
            <div class="footDiv1">
              <p>关于我们</p>
              <span class="tiaozhuan" @click="$router.push('/introduction')">公司简介</span>
              <span class="tiaozhuan" @click="$router.push('/news')">新闻中心</span>
              <span class="tiaozhuan" @click="$router.push('/career')">加入我们</span>
            </div>
            <div>
              <p>联系我们</p>
              <span>联系地址：<br>天津市河北区鸿顺里街诺城广场1号楼18层17号</span>
              <span>联系邮箱：<br>bd@huiyan-tech.com</span>
              <span>联系电话：<br>022-23726181（常规咨询）<br>022-23734871（商务咨询）
<!--                <br>刘经理&nbsp;&nbsp;156-9220-3015（商务咨询）<br>许经理&nbsp;&nbsp;189-1031-9816（商务咨询）-->
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="footerCenterRight">
        <div>
          <p>微信订阅号</p>
          <div class="greyBox">
            <div>
              <img src="@/assets/imgs/bottom_wc.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="footerCenterBottom">
        <div class="recordBox">
          <span>© 2018-2025 慧言科技(天津)有限公司 版权所有</span>
          <a href="https://beian.miit.gov.cn/" target="_blank">津ICP备18004259号-1</a>
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12019202000300" target="_blank">
            <img src="../assets/imgs/bottom_sec.jpg"/>
            <span style="padding-left: 8px;">津公网安备 12019202000300号</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hyFooter",
  data() {
    return {
      thisYear: "",
    };
  },
  mounted() {
    this.nowtime()
  },
  methods: {
    goAIplatform() {
      window.open('https://ai.huiyan-tech.com/','_blank')
    },
    goHYhuiting() {
      window.open('https://huiting.huiyan-tech.com','_blank')
    },
    goHHditing() {
      window.open('https://diting.huiyan-tech.com/#/login','_blank')
    },
    nowtime() {
      let nowDate = new Date();
      let date = {
        // 获取当前年份
        year: nowDate.getFullYear(),
      };
      this.thisYear = date.year;
    },
  }
};
</script>

<style lang="less" scoped>
#Footer {
  position: relative;
  width: 100%;
  min-height: 396px;
  background: #1E1D2F;
}
.footerCenter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1200px;
  min-height: 346px;
  padding-top: 50px;
  margin: 0 auto;
  .footerCenterLeft {
    width: 900px;
    height: max-content;
    .footerMsg {
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      div {
        display: flex;
        div {
          display: flex;
          align-content: baseline;
          flex-wrap: wrap;
          min-width: 72px;
          p,span {
            width: 100%;
            color: #FFFFFF;
          }
          p {
            margin-bottom: 34px;
            font-size: 18px;
            font-weight: 600;
          }
          span {
            margin-bottom: 26px;
            font-size: 14px;
          }
          span.tiaozhuan {
            cursor: pointer;
          }
        }
        .footDiv1 {
          width: 220px;
        }
      }
    }
  }
  .footerCenterRight {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: end;
    width:140px;
    height: max-content;
    div {
      width: 100%;
      text-align: left;
      p {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 600;
        color: #FFFFFF;
      }
      .greyBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        div {
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          justify-content: center;
          width: 140px;
          height: 140px;
          background: #FFFFFF;
          border-radius: 2px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .footerCenterBottom {
    width: 100%;
    height: 60px;
    text-align: center;
    color: #fff;
    .recordBox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: left;
      font-size: 12px;
      font-weight: 400;
      color: #A9A9A9;
      a {
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        color: #A9A9A9;
        cursor: pointer;
        img {
          width: 18px;
          height: 20px;
        }
      }
      a:nth-child(2) {
        margin: 0 50px;
      }
    }
  }
}
</style>
